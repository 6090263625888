<template>
  <div class="login">
    <van-form>
      <van-field
        v-model="form.phone"
        maxlength="11"
        type="tel"
        placeholder="请输入手机号码"
        label="手机号"
      />
      <van-field
        v-model="form.code"
        label="验证码"
        type="number"
        center
        clearable
        maxlength="6"
        autocomplete="one-time-code"
        placeholder="请输入验证码"
        class="input-code"
      >
        <template slot="right-icon">
          <div
            :class="['code', { djs: form.phone.length < 11 }]"
            v-show="sendAuthCode"
            @click="getCode"
          >
            发送验证码
          </div>
          <div class="code djs" v-show="!sendAuthCode">
            <span>{{ auth_time }}s</span>后重新发送
          </div>
        </template>
      </van-field>
      <div
        class="submit"
        @click="onSubmit"
        v-if="form.phone.length == 11 && form.code.length == 6"
      >
        立即登录
      </div>
      <div class="submit opacity" v-else>立即登录</div>
    </van-form>
  </div>
</template>

<script>
import { loginSms, login } from "@/api/changXueSupreme/index.js";
import { mapMutations, mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {
        phone: "",
        code: "",
      },
      sendAuthCode: true,
      checked: false,
      auth_time: 0,
      state: false,
    };
  },
  computed: {
    ...mapGetters(["changXueSupreme_productNo"]),
  },
  created() {
    console.log("测试");
  },
  methods: {
    ...mapMutations(["changXueSupreme/SET_TOKEN"]),
    async getCode() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone } = this.form;
      if (!filterPhone.test(phone)) {
        this.$toast.fail("请输入正确的手机号码");
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await loginSms({
        phone,
        productNo: this.changXueSupreme_productNo,
      });
      this.$toast.clear();
      if (res.code != 0) {
        this.$toast.fail(res.message);
      } else {
        // 设置倒计时秒
        this.sendAuthCode = false;
        this.auth_time = 60;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },
    onSubmit() {
      let filtercode = /^\d{6}$/;
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone, code } = this.form;

      if (!phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(phone)) {
        this.$dialog.alert({ message: "请输入正确的手机号码" });
      } else if (!code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(code)) {
        this.$dialog.alert({ message: "请输入正确的验证码" });
      } else {
        this.handleSubmit();
      }
    },
    async handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });

      const res = await login({
        ...this.form,
        productNo: this.changXueSupreme_productNo,
      });

      this.$toast.clear();
      if (res.code == 0) {
        this["changXueSupreme/SET_TOKEN"]({ token: res.data });
        this.$router.push("/changXueSupreme/receive/homePage");
      } else {
        this.$toast.fail(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  ::v-deep .van-form {
    padding-top: 68px;

    .van-cell {
      padding: 0 0 0 24px;
      height: 96px;
      line-height: 96px;

      &::after {
        left: 24px;
        right: 0;
        border-bottom: 1px solid #eeeeee;
      }
      &__title {
        font-size: 34px;
        color: #333333;
      }
    }

    .code {
      width: 218px;
      height: 44px;
      text-align: center;
      line-height: 44px;
      color: #01befd;
      font-size: 28px;
      font-weight: 4000;
      border-left: solid 2px #eeeeee;

      &.djs {
        color: #cccccc;
      }
    }

    .van-field__control {
      color: #333333;
      font-size: 34px;
      font-weight: 400;
      &::-webkit-input-placeholder {
        font-size: 34px;
        color: #cccccc;
        font-weight: 400;
      }
    }
  }
  .submit {
    display: block;
    text-align: center;
    width: 650px;
    height: 88px;
    line-height: 88px;
    color: #fff;
    font-size: 32px;
    text-align: center;
    border-radius: 44px;
    margin: 64px auto 0;
    background: #01befd;

    &.opacity {
      opacity: 0.5;
    }
  }
}
</style>
